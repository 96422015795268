import { useIdentity } from '../providers/identityContext'
import { windowExists } from '../utils'

function Logout() {
  const { logout } = useIdentity()

  if (!windowExists) {
    return null
  }

  logout()
  return null
}

export default Logout
